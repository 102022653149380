@import '../../styles/variables';

.layout {
  height: 100%;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 24px;

  .controls {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .title {
      margin-bottom: 24px;
      margin-right: 16px;
    }

    .smallSelect {
      width: 120px;
    }

    .select {
      width: 220px;
    }
  }

  .content {
    margin-top: 16px;
    padding: 24px;
    overflow-y: auto;
    background-color: $white-color !important;

    .empty {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

.tableLayout {
  height: 100%;
  width: 100%;
  padding: 24px;

  .breadcrumbsContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .breadcrumbs {
    margin-left: 24px;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
  }

  .content {
    margin-top: 16px;
    padding: 24px;

    .tableRow {
      cursor: pointer;
    }

    .crossIcon {
      color: #f5222d;
      font-size: 20px;
    }

    .crossIconDisabled {
      color: #bfbfbf;
      font-size: 20px;
    }

    .checkIcon {
      color: #52c41a;
      font-size: 20px;
    }
  }
}

.modalDescription {
  margin: 24px 0;
}

