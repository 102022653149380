@import '../../styles/variables';

.layout {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  background-color: $white-color;
}
